<template>
    
    <div :class="{ 'spalte': true, 'options': $store.state.seitenLeisteStatus }">
        <VersenSpalte />
    </div> 
    
</template>
  
<script>
import VersenSpalte from './verse/VersenSpalte.vue';

export default {
    name: 'Spalte',
    components: {
        VersenSpalte
    },
    props: {
    }
}
</script>

<style scoped lang="scss">
    .spalte {
        position: relative;
        width: 375px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        transition: margin-left 0.5s; 

        &.options {
            @media only screen and (min-width: 659px) {
                margin-left: 280px;
            }
        }
    }
</style>
<template>
    <div class="suchBtn">
    </div>
</template>

<script>
export default {
    name: "SearchBtn"
}
</script>

<style scoped lang="scss">
    .suchBtn {
        width: 10px;
        height: 10px;
        border: 2px solid #333;
        border-radius: 50%;
        display: inline-block;
    }

    .suchBtn::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 3px;
        background-color: #333;
        top: 90%;
        left: 100%;
        transform: rotate(50deg);
        transform-origin: top left;
    }
</style>
<template>
  <Navi />
  <Main />
</template>

<script>
import Navi from "./components/Navi.vue";
import Main from "./components/Main.vue";
//import Footer from "./components/Footer.vue";

export default {
  name: 'App',
  components: {
    Navi,
    Main,
    //Footer,
  }, /*
  data() {
    return {
      //versMenue: true,
      title: 'Seitenleiste schließen',
      settingsMenue: true,
      searchMenue: false,
    } */
  //},
  watch: {
  },
  methods: {
  },
  updated() {
  },
  mounted() {
    // Beim Laden der Seite prüfen
    //if (window.innerWidth <= 660) {
      //this.versMenue = false;
    //}
  }
}
</script>

<style lang="scss">
body {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}
#app {
  position: relative;
  @font-face {
    font-family: "sandi";
    src: url(fonts/sandi.ttf);
    font-style: normal;
    font-weight: 400;
  }
  height: 100vh;
  font-family: "sandi";
  font-size: 14px;
  letter-spacing: 0.025em;
  margin: 0;
  padding: 0;
}
</style>

<template>
    
    <div v-if="vers.hinweiseVorhanden === false" :class="{ 'vers-container': true }" :id="'vers_' + vers.nr">
        {{ vers.nr }}. {{ vers.inhalt }}

        <div v-if="$store.state.verweiseStatus && verweiseCheck(vers.nr) !== '' && verweiseLoaded">
            <button v-for="verweis in this.verweisDaten[verweisId]" type="button" class="verweis-btn" 
                :key="verweis.buchid + verweis.kapitel + verweis.vers"
                @click="handleVerweisClick(verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)" 
                @mouseenter="verweis_show($event, vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)" 
                @mouseleave="verweis_hide(vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)">
                {{ verweis.buchKrzl + ' ' + verweis.kapitel + ':' + verweis.vers }}
            </button>
            <div v-for="verweis in this.verweisDaten[verweisId]"
                :key="vers.nr + verweis.buchid + verweis.kapitel + verweis.vers"
                :id="vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers" 
                class="verweisvers">
                    <span style="text-decoration: underline; font-weight: bold;">{{ verweis.buchName + ' ' + verweis.kapitel + ':' + verweis.vers }}</span> 
                    <span>{{ ' ' + verweis.inhalt.replace(/\[\d+\]/g, '') }}</span>
            </div>
        </div>

    </div>
    <div v-else :class="{ 'vers-container': true }" :id="'vers_' + vers.nr">
        <div v-html="getModifiedVersInhalt(vers)"></div>

        <div v-if="$store.state.hinweiseStatus && vers.hinweiseVorhanden === true && hinweiseLoaded">
            <div v-for="fussNote in getIdsAusVersInhalt(vers.inhalt)" 
                :key="fussNote" 
                class="hinweis-container" 
                :id="'hinweisid_' + fussNote"
                v-html="hinweisDaten[vers.nr][fussNote]">
            </div>
        </div>
        <!--
        :versMenue="$store.state.seitenLeisteStatus"
        :hinweise="$store.state.hinweiseStatus"
        :verweise="$store.state.verweiseStatus"
    -->

        <div v-if="$store.state.verweiseStatus && verweiseCheck(vers.nr) !== '' && verweiseLoaded" class="verweise-zeile"> 
            <div>
                <button v-for="verweis in this.verweisDaten[verweisId]" type="button" class="verweis-btn" 
                    :key="verweis.buchid + verweis.kapitel + verweis.vers"
                    @click="handleVerweisClick(verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)" 
                    @mouseenter="verweis_show($event, vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)" 
                    @mouseleave="verweis_hide(vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers)">
                    {{ verweis.buchKrzl + ' ' + verweis.kapitel + ':' + verweis.vers }}
                </button>
            </div>
            <div v-for="verweis in this.verweisDaten[verweisId]"
                :key="vers.nr + verweis.buchid + verweis.kapitel + verweis.vers"
                :id="vers.nr + '_' + verweis.buchid + '_' + verweis.kapitel + '_' + verweis.vers" 
                class="verweisvers">
                <span style="text-decoration: underline; font-weight: bold;">{{ verweis.buchName + ' ' + verweis.kapitel + ':' + verweis.vers }}</span> 
                <span>{{ ' ' + verweis.inhalt.replace(/\[\d+\]/g, '') }}</span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Vers", 
    props: [ 'vers', 'hinweisDaten', 'verweisDaten', 'hinweiseLoaded', 'verweiseLoaded', 'buchid', 'kapitel' ], 
    data() {
        return {
            verweisId: '',
            hoverTimeouts: {},
        }
    },
    methods: {
        formatVerweis(txt) {
            let formated = `<u>${txt}</u>`;
            return formated;
        }, 

        handleVerweisClick(verweisKey) {
            this.$emit('verweis-item-click', verweisKey);
        },

        showHinweis(hinweisid, event) {
            const div = document.getElementById(`hinweisid_${hinweisid}`);
            
            if (div) {
                // Das Element, das das Event ausgelöst hat
                const element = event.target; 

                // Hol die Position des Elements relativ zum Viewport
                const rect = element.getBoundingClientRect(); 
                
                div.style.bottom = 'auto';
                div.style.top = rect.top + 25 + 'px';
                div.style.opacity = '1';
                div.style.height = 'auto';
            }
        },

        hideHinweis(hinweisid) {
            const div = document.getElementById(`hinweisid_${hinweisid}`);
            if (div) {
                div.style.bottom = '-100px';
                div.style.top = 'auto';
                div.style.opacity = '0';
                div.style.height = '0';
            }
        },

        verweis_show(event, verweisId) {
            const div = document.getElementById(verweisId);
            if (div) {
                // Das Element, das das Event ausgelöst hat
                const element = event.target; 

                // Hol die Position des Elements relativ zum Viewport
                const rect = element.getBoundingClientRect(); 
                
                div.style.bottom = 'auto';
                div.style.top = rect.top + 25 + 'px';
                div.style.opacity = '1';
                div.style.height = 'auto';
            }
        },

        verweis_hide(verweisId) {
            const div = document.getElementById(verweisId);
            if (div) {
                div.style.bottom = '-100px';
                div.style.top = 'auto';
                div.style.opacity = '0';
                div.style.height = '0';
            }
        },

        verweiseCheck(versNr) {
            let verweiseKey = this.buchid + '_' + this.kapitel + '_' + versNr;
            if (verweiseKey in this.verweisDaten) {
                this.verweisId = verweiseKey;
                return verweiseKey;
            }
            return '';
        },

        // Bei den Versen, die Hinweise innehaben, sollen die Klammern ink. Zahl verschwinden, oder markiert werden
        getModifiedVersInhalt(vers) {            
            const regex = /(\S+)\[([\d]+)\]/g;
            let result = vers.inhalt.replace(regex, (match, vorherigesWort, zahlInKlammer) => {
                if (this.$store.state.hinweiseStatus) {
                    // Sonderzeichen am Ende des Wortes erfassen und vom Wort trennen
                    //const sonderzeichenRegex = /([.,:;!?«»]*)$/;
                    //const nachzeichen = vorherigesWort.match(sonderzeichenRegex)[0]; // Extrahiert die Sonderzeichen
                    //vorherigesWort = vorherigesWort.replace(sonderzeichenRegex, ''); // Entfernt die Sonderzeichen vom Wort

                    return `${vorherigesWort}<label 
                            class="hint-label" 
                            data-hinweis-id="${zahlInKlammer}" 
                            style="cursor: help; 
                                color: #00457c; 
                                font-size: 12px; 
                                font-weight: bold;" >[${zahlInKlammer}]</label>`;
                } else {
                    return '<label><span>' + vorherigesWort + '</span><span style="display: none;">[' + zahlInKlammer + ']</span></label>';
                }
            });

            this.$nextTick(() => {
                const labels = document.querySelectorAll(".hint-label");
                labels.forEach(label => {
                    const id = label.dataset.hinweisId;
                    label.addEventListener("mouseenter", (event) => this.showHinweis(id, event));
                    label.addEventListener("mouseleave", () => this.hideHinweis(id));
                });
            });

            return vers.nr + '. ' + result;
        },

        // diese Funktion soll mir aus den Versinhalten die HinweisIDs auslesen und rueckgeben
        getIdsAusVersInhalt(versInhalt) {
            const regex = /\[\d+\]/g;
            let matches = versInhalt.match(regex) || [];

            // Extrahiere Ziffern aus den gefundenen Übereinstimmungen
            matches = matches.map(function(match) {
                let idMatch = /\[(\d+)\]/.exec(match);
                return idMatch ? idMatch[1] : null;
            }).filter(function(id) {
                return id !== null;
            });

            return matches;
        },
    }
}
</script>

<style scoped lang="scss">

    .vers-container {
        position: relative;
        height: auto;
        width: calc(100% - 10px); 
        padding: 5px;
        margin-bottom: 5px;
        background-color: #FFF;

        .vers-container-66 {
            position: relative;
            height: auto;
            width: 66%;
            padding: 0;
            margin: 0;
            float: left;
        }

        .hinweis-container {
            position: fixed;
            bottom: -100px;
            top: auto;
            opacity: 0;
            height: 0;
            /* Width - 2 x 10px padding, - 2 x 5px margin */
            width: calc(100% - 30px);
            max-width: 345px;
            overflow: hidden;
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            background-color: #fbfdff;
            padding: 10px;
            box-shadow: 0 0 10px #00457c;
            z-index: 1;
        }

        .verweis-container {
            position: relative;
            height: auto;
            width: calc(95% - 10px);
            padding: 5px;
            margin-top: 5px;
            font-weight: normal;
            float: right;

            a {
                all: unset;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .verweise-zeile {
            position: relative; 
            width: 100%; 
            height: auto; 
            float: left;
        }

        button {
            all: unset; 

            &.verweis-btn {
                position: relative;
                height: auto;
                width: auto; 
                padding: 0 5px 5px;
                margin-top: 2px;
                font-size: .75rem;
                font-weight: normal;
                font-style: italic;
                float: right;
                cursor: pointer;
                border: outset transparent 1px;
                border-bottom: none;

                &:hover {
                    background-color: #f5f5f5;
                    border: 1px solid #d6d6d6;
                    border-bottom: none;
                }
            }
        }

        .verweisvers {
            position: fixed;
            bottom: -100px;
            top: auto;
            opacity: 0;
            height: 0;
            /* Width - 2 x 10px padding, - 2 x 5px margin */;
            width: calc(100% - 30px); 
            max-width: 345px;
            overflow: hidden;
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            background-color: #f5f5f5;
            padding: 10px;
            box-shadow: 0 0 10px black;
            z-index: 1;
        }
    }
    
</style>
import { createStore } from "vuex";

export default createStore({
  state: {
    ladeStatus: false,
    ladeInfo: 'Laden...',

    myRoot: '',

    seitenLeisteStatus: true,
    seitenLeisteButtonTitle: 'Seitenleiste schließen',

    seitenLeisteTtl: 'Ansichtsoptionen',

    settingsMenueStatus: true,
    hinweiseStatus: true,
    verweiseStatus: true,
    hinweiseTtl: 'Hinweise ausblenden', 
    verweiseTtl: 'Verweise ausblenden', 

    buchNamenArray: {},

    searchMenueStatus: false,
    atSuchErgebnisAnsicht: true,
    ntSuchErgebnisAnsicht: true,
    atBtnTtl: 'Ergebnisse im AT ausblenden',
    ntBtnTtl: 'Ergebnisse im NT ausblenden',
    suchString: '',

    //suchErgebnisArray: {},
    atSuchErgebnisse: {},
    atBuchIds: {},
    ntSuchErgebnisse: {},
    ntBuchIds: {},

    suchWorte: new Set(),
  },

  mutations: {
    suchString(state, value) {
      state.suchString = value
    },

    atSuchErgebnisAnsicht(state) {
      const status = !state.atSuchErgebnisAnsicht;
      state.atSuchErgebnisAnsicht = status

      state.atBtnTtl = status ? 'Ergebnisse im AT ausblenden' : 'Ergebnisse im AT einblenden'
    },

    ntSuchErgebnisAnsicht(state) {
      const status = !state.ntSuchErgebnisAnsicht;
      state.ntSuchErgebnisAnsicht = status

      state.ntBtnTtl = status ? 'Ergebnisse im NT ausblenden' : 'Ergebnisse im NT einblenden'
    },

    changeHinweiseAnsicht(state, value) {
      state.hinweiseStatus = value;
      state.hinweiseTtl = value ? 'Hinweise ausblenden' : 'Hinweise einblenden';
    },

    changeVerweiseAnsicht(state, value) {
      state.verweiseStatus = value;
      state.verweiseTtl = value ? 'Verweise ausblenden' : 'Verweise einblenden';
    },

    inBibelSucheAnzeigen(state) {
      state.searchMenueStatus = true;
      state.seitenLeisteTtl = 'Volltextsuche';
      state.settingsMenueStatus = false;
      state.seitenLeisteStatus = true;

      console.log('inBibelSucheAnzeigen');
    },

    einstellungenAnzeigen(state) {
      state.settingsMenueStatus = true;
      state.seitenLeisteTtl = 'Ansichtsoptionen';
      state.searchMenueStatus = false;
      
      console.log('einstellungenAnzeigen');
    },

    seitenLeisteToggle(state) {
      state.seitenLeisteStatus = !state.seitenLeisteStatus;

      const newTitle = state.seitenLeisteStatus ? 'Seitenleiste schließen' : 'Seitenleiste öffnen';
      state.seitenLeisteButtonTitle = newTitle;
    },
    
    smartPhoneCheck(state) {
      state.seitenLeisteStatus = false;
    },

    // Schaut drauf ob ich in der Entwicklungsumgebung oder live bin
    getMyRoot(state) { 
      let myRoot = window.location.protocol + '//' + window.location.hostname; 
      if (window.location.hostname.includes('local') || window.location.hostname.includes('192.168')) {
          myRoot = window.location.protocol + '//localhost/vue/bibelx'; //Localer Root
      }
      state.myRoot = myRoot;
    },

    starteLadeschleife(state, ladeTxt) {
      state.ladeStatus = true; 
      state.ladeInfo = ladeTxt === '' ? 'Laden...' : ladeTxt; 
    },

    beendeLadeschleife(state) {
      state.ladeStatus = false; 
      state.ladeInfo = 'Laden...'; 
    },


    SET_BUCHNAMEN_ARRAY(state, buchNamenArray) {
      state.buchNamenArray = buchNamenArray;
    },


    SET_AT_RESULT(state, atSuchErgebnisse) {
      state.atSuchErgebnisse = atSuchErgebnisse;
    },
    REMOVE_AT_BID(state) {
      delete state.atSuchErgebnisse.bid; // Entfernt den "bid"-Key direkt aus dem AT-Result
    },
    SET_AT_BID(state, bid) {
      state.atBuchIds = bid;
    },
    SET_NT_RESULT(state, ntSuchErgebnisse) {
      state.ntSuchErgebnisse = ntSuchErgebnisse;
    },
    REMOVE_NT_BID(state) {
      delete state.ntSuchErgebnisse.bid; // Entfernt den "bid"-Key direkt aus dem NT-Result
    },
    SET_NT_BID(state, bid) {
      state.ntBuchIds = bid;
    }
  },

  getters: {

  },

  actions: {
    async getBookNames() {
      if (this.state.myRoot === '') {
        this.commit('getMyRoot');
      }

      if (Object.keys(this.state.buchNamenArray).length === 0) {
        try {
          console.log('getBookNames()');

          this.commit('starteLadeschleife', 'Lade Metadaten ...');

          const response = await fetch(this.state.myRoot + '/api/ajax_buch_namen.php');

          if (!response.ok) {
            throw new Error('Netzwerkantwort war nicht okay');
          }

          const buchNamenArray = await response.json();

          // State in Vuex setzen
          this.commit('SET_BUCHNAMEN_ARRAY', buchNamenArray);
        } catch (error) {
          console.error('Fehler bei den Buchnamen:', error);
          this.commit('beendeLadeschleife');
        }
      }
    },


    async sucheStarten() {
      if (this.state.myRoot === '') {
        this.commit('getMyRoot');
      }

      if (Object.keys(this.state.buchNamenArray).length === 0) {
        console.log('dispatch getBookNames');
        await this.dispatch('getBookNames');
      }

      // Bereite die Daten vor
      const dataToSend = {
        sucheNach: this.state.suchString
      };

      const trimmedWord = this.state.suchString.trim();
      // Überprüfe, ob das Wort leer ist oder weniger als 3 Zeichen hat
      if (trimmedWord.length < 3) {
        alert('Das Suchwort muss ausgefüllt und mindestens 3 Zeichen lang sein.');
        return; // Beende die Funktion, um die Suche nicht auszuführen
      } else {

        // hier nur weitermachen, wenn Suchbegrifflaenge groesser 3 Chars
        try {
          this.commit('starteLadeschleife', 'Lade Ergebnisse ...');
  
          // Fetch-Anfrage an das PHP-Skript
          const response = await fetch(this.state.myRoot + '/api/ajax_suche.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
          });
  
          if (!response.ok) {
            throw new Error('Netzwerkantwort war nicht okay');
          }
  
          // Ergebnisse vom Server abrufen
          const suchErgebnisArray = await response.json(); 

          // State in Vuex setzen
          //this.commit('SET_SUCHERGEBNIS_ARRAY', suchErgebnisArray);

          // AT-Resultat in Vuex setzen
          const atSuchErgebnisse = suchErgebnisArray.AT;
          this.commit('SET_AT_RESULT', atSuchErgebnisse);

          // BuecherIDs als Array extrahieren und in einen eigenen State setzen
          const atBuchIds = atSuchErgebnisse.bid;
          this.commit('SET_AT_BID', atBuchIds);

          // BuecherIDs aus AT-Resultat entfernen
          this.commit('REMOVE_AT_BID');


          // NT-Resultat in Vuex setzen
          const ntSuchErgebnisse = suchErgebnisArray.NT;
          this.commit('SET_NT_RESULT', ntSuchErgebnisse);

          // BuecherIDs als Array extrahieren und in einen eigenen State setzen
          const ntBuchIds = ntSuchErgebnisse.bid;
          this.commit('SET_NT_BID', ntBuchIds);

          // BuecherIDs aus NT-Resultat entfernen
          this.commit('REMOVE_NT_BID');

          this.state.suchWorte.add(this.state.suchString);

          console.log('this.state.atSuchErgebnisse: ', this.state.atSuchErgebnisse);
          console.log('this.state.ntSuchErgebnisse: ', this.state.ntSuchErgebnisse);
  
          this.commit('beendeLadeschleife');
  
          // Hier kannst du die Ergebnisse weiterverarbeiten, z.B. anzeigen
        } catch (error) {
          console.error('Fehler bei der Suche:', error);
          this.commit('beendeLadeschleife');
        }
      }
    },
  },

  modules: {

  },
})

<template>

    <!-- Die Hinweise Ein und Ausschalten -->
    <div v-if="$store.state.settingsMenueStatus" 
        :class="{ 'settings-zeile' : true, 'aktiv': $store.state.hinweiseStatus }" 
        :title="$store.state.hinweiseTtl" 
        >
        <label class="switch">
            <input 
                type="checkbox" 
                class="checkbox" 
                :checked="$store.state.hinweiseStatus"
                :title="$store.state.hinweiseTtl"
                v-model="checkHinweise"
            />
            <div class="switch-btn"></div>
        </label>
        <span :class="{ 'option': true }">Hinweise</span>
    </div>

    <!-- Die Verweise Ein und Ausschalten -->
    <div v-if="$store.state.settingsMenueStatus" 
        :class="{ 'settings-zeile' : true, 'aktiv': $store.state.verweiseStatus }" 
        :title="$store.state.verweiseTtl" 
        >
        <label class="switch">
            <input 
                type="checkbox" 
                class="checkbox" 
                :checked="$store.state.verweiseStatus"
                :title="$store.state.verweiseTtl"
                v-model="checkVerweise"
            />
            <div class="switch-btn"></div>
        </label>
        <span :class="{ 'option': true }">Verweise</span>
    </div>

</template>
  
<script>

export default {
    name: 'Settings',

    components: {
    },

    data() {
        return {
        }
    },
    props: {
    },

    watch: {
    },

    methods: {
    },

    computed: {
        checkHinweise: {
            get() {
                return this.$store.state.hinweiseStatus
            },
            set(value) {
                this.$store.commit('changeHinweiseAnsicht', value)
            }
        },

        checkVerweise: {
            get() {
                return this.$store.state.verweiseStatus
            },
            set(value) {
                this.$store.commit('changeVerweiseAnsicht', value)
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .settings-zeile {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
        height: 20px;
        padding: 7px 10px;
    }

    .option {
        font-size: 13px;
        letter-spacing: 0.025em;
        padding-left: 20px;
    }

    .aktiv {
        font-weight: bold;
        background-color: #F0F0F0;
    }

    .switch {
        display: block;
        width: 32px;
        cursor: pointer;
    }
    .checkbox {
        position: absolute;
        opacity: 0;
    }

    .switch-btn {
        position: relative;
        width: 100%;
        height: 16px;
        background-color: #e5e5e5;
        border-radius: 10px;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.3);
    }

    .switch-btn:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        background: linear-gradient(#FFF, #f2f2f2);
        left: 1px;
        top: 1px;
        border-radius: 50%;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.25);
        transition: all 150ms ease-out;
    }

    input[type=checkbox]:checked + .switch-btn:before {
        left: 52.5%;
    } 

    input[type=checkbox]:checked + .switch-btn {
        background-color: #999; //#47CB87;
    }
</style>
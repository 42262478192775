<template>
    <button class="nextBtn triangle">

    </button>
</template>

<script>
export default {
name: "triangleButtonRight"
}
</script>

<style scoped lang="scss">
button {
all: unset;
cursor: pointer;

    &.nextBtn {
        width: 10px;
        transform: rotate(0.25turn) !important;

        &.triangle {
            display: block;
            width: 0;
            height: 0;
            border-left: 7px solid transparent; /* Pfeilspitze nach links */
            border-right: 7px solid transparent; /* Pfeilspitze nach rechts */
            border-bottom: 7px solid #666; /* Füllfarbe des Dreiecks */
        }
    }
}
</style>
<template>
    <div v-if="$store.state.ladeStatus" class="loader-overlay">
        <div class="loader">
            <div class="loader-text">{{ $store.state.ladeInfo }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
};
</script>

<style scoped>
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader::before {
    content: "";
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loader-text {
    margin-top: 10px;
    color: #fff;
    font-size: 18px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
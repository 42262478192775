<template>
    <ul>
        <li v-for="(buch, index) in buchNamen" 
            :key="index" 
            :id="'buchId' + index" 
            :class="{ second: secondCheck(index), active: aktivCheck(index), nt: neuesTestamentCheck(index) }" 
            @click="handleBuchNamenClick(index)"
            >
            {{ buch.name }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "SelectBuchField",
    props: [ 'buchNamen', 'buchid' ],
    methods: {
        handleBuchNamenClick(neueBuchId) {
            this.$emit('item-click', neueBuchId);
        },

        secondCheck(index) { 
            if (this.buchid != index) {
                if (index % 2 == 0) {
                    return true;
                }
            }
            return false;       
        },

        aktivCheck(index) { 
            if (this.buchid == index) {
                return true;
            }
            return false;       
        },

        neuesTestamentCheck(index) { 
            if (index > 39) {
                return true;
            }
            return false;  
        },
    }
}
</script>

<style scoped lang="scss">
ul {
    all: unset;
    position: absolute;
    top: 20px;
    width: calc(100% - 10px);
    max-height: 75vh;
    background-color: #CCC;
    padding-left: 10px;
    overflow: hidden;
    overflow-y: scroll;
    display: none;

    scrollbar-width: none; /* Für Firefox - Breite des unsichtbaren Scrollbalkens */
    &::-webkit-scrollbar {
        width: 1px; /* Breite des unsichtbaren Scrollbalkens */
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent; /* Farbe des Scrollbalken-Thumb */
    }
    &::-webkit-scrollbar-track {
        background-color: transparent; /* Farbe des Scrollbalken-Tracks */
    }

    li {
        all: unset;
        position: relative;
        width: calc(100% - 8px);
        padding: 5px;
        transition: padding 0.2s ease;
        color: #FFF;
        background-color: #333;

        &.nt {
            background-color: #EEE;
            color: #333;
        }

        &.second {
            background-color: #666;

            &.nt {
                background-color: #DDD;
            }
        }

        &:hover {
            cursor: auto;

            &:not(.active) {
                cursor: pointer;
                padding-left: 7px;
                background-color: #CCC;
                color: #333;
            }
        }

        &.active {
            width: calc(100% - 9px);
            background-color: #FFF;
            font-weight: bolder;
            color: #000;
            border-right: 1px inset #DDD;

            &.nt {
                background-color: #000;
                font-weight: bolder;
                color: #FFF;
            }
        }
    }
    &:hover {
        display: flex !important;
        flex-direction: column;
        z-index: 1;
    }
}
</style>
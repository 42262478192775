<template>
    
    <div class="such-ergebnis-cntnr">

        <div class="columns">
            <!-- Linke Spalte -->
            <div class="column">
                <div
                    v-for="(buchId, index)  in leftColumnBooks"
                    :key="buchId"
                    :class="{ 'book-entry': true, 'second': secondCheckLeft(index), 'nt': isInNtBuchIds(buchId), 'inaktiv': isInaktiv(buchId) }"
                    :title="$store.state.buchNamenArray[buchId].name + (this.isInaktiv(buchId) ? ' - Ergebnisse Anzeigen' : ' - Ergebnisse Ausblenden')"
                >
                    {{ $store.state.buchNamenArray[buchId].name_kurz }} ({{ getEntryCount(buchId) }})
                </div>
            </div>

            <!-- Rechte Spalte -->
            <div class="column">
                <div
                    v-for="(buchId, index)  in rightColumnBooks"
                    :key="buchId"
                    :class="{ 'book-entry': true, 'second': secondCheckRight(index), 'nt': isInNtBuchIds(buchId), 'inaktiv': isInaktiv(buchId) }"
                    :title="$store.state.buchNamenArray[buchId].name + (this.isInaktiv(buchId) ? ' - Ergebnisse Anzeigen' : ' - Ergebnisse Ausblenden')"
                >
                    {{ $store.state.buchNamenArray[buchId].name_kurz }} ({{ getEntryCount(buchId) }})
                </div>
            </div>
        </div>

    </div>

</template>

<script>

export default {
    name: "SuchResult",
    data() {
        return {
        }
    },
    computed: {
        // Alle Buch-IDs, abhängig vom Testament (altes oder neues)
        bookIds() {
            return [...Object.keys(this.$store.state.atBuchIds), ...Object.keys(this.$store.state.ntBuchIds)];
        },

        // Buch-IDs aufteilen in zwei Spalten
        leftColumnBooks() {
            const half = Math.ceil(this.bookIds.length / 2);
            return this.bookIds.slice(0, half);
        },

        rightColumnBooks() {
            const half = Math.ceil(this.bookIds.length / 2);
            return this.bookIds.slice(half);
        },
    },
    methods: {
        // Anzahl der Einträge pro Buch (buchid_kapitel_vers)
        getEntryCount(buchId) {
            const atEntries = this.$store.state.atBuchIds[buchId] ? this.$store.state.atBuchIds[buchId].length : 0;
            const ntEntries = this.$store.state.ntBuchIds[buchId] ? this.$store.state.ntBuchIds[buchId].length : 0;
            return atEntries + ntEntries;
        },

        // Prüfe, ob die Buch-ID im Alten Testament (atBuchIds) enthalten ist
        isInAtBuchIds(buchId) {
            return !!this.$store.state.atBuchIds[buchId];
        },

        // Prüfe, ob die Buch-ID im Neuen Testament (ntBuchIds) enthalten ist
        isInNtBuchIds(buchId) {
            return !!this.$store.state.ntBuchIds[buchId];
        },

        secondCheckLeft(index) { 
            return (index % 2 == 0) ? true : false;     
        },

        secondCheckRight(index) { 
            return (index % 2 == 0) ? false : true;     
        },

        isInaktiv(buchId) {
            if (!this.$store.state.atSuchErgebnisAnsicht) {
                if (this.isInAtBuchIds(buchId)) {
                    return true;
                }
            }

            if (!this.$store.state.ntSuchErgebnisAnsicht) {
                if (this.isInNtBuchIds(buchId)) {
                    return true;
                }
            }

            return false;
        },
    },
}
</script>

<style scoped lang="scss">
    .such-ergebnis-cntnr {
        position: relative;
        width: calc(100% - 40px);
        height: calc(100% - 280px);
        margin: 15px auto 0;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        overflow-y: scroll;
        scrollbar-width: none; 
        
        /* Für Firefox - Breite des unsichtbaren Scrollbalkens */
        &::-webkit-scrollbar {
            width: 1px; /* Breite des unsichtbaren Scrollbalkens */
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent; /* Farbe des Scrollbalken-Thumb */
        }
        &::-webkit-scrollbar-track {
            background-color: transparent; /* Farbe des Scrollbalken-Tracks */
        }
    }

    .columns {
        position: relative;
        width: 100%;
        max-width: 260px;
        display: flex;
        justify-content: space-between;
    }

    .column {
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .book-entry {
        position: relative;
        width: calc(100% - 10px);
        display: flex;
        justify-content: space-between;
        padding: 5px;
        word-wrap: break-word;
        color: #FFF;
        background-color: #333;
        transition: margin 0.3s, width 0.3s; 

        &.inaktiv {
            opacity: 0.3;
        }

        &.nt {
            background-color: #EEE;
            color: #333;
        }

        &.second {
            background-color: #666;

            &.nt {
                background-color: #DDD;
            }
        }

        &:hover {
            cursor: pointer;
            width: calc(100% - 15px);
            margin-left: 5px;
        }
    }
</style>
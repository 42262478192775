<template>
    <input 
        type="text"
        placeholder="placeholder"
        title="title"
        v-model="suchString"
    />
</template>

<script>

export default {
    name: "EingabeZeile",
    data() {
        return {
            placeholder: '*Suchbegriff eingeben',
            title: 'In den Bibelversen suchen' 
        }
    },
    computed: {
        suchString: {
            get() {
                return this.$store.state.suchString
            },
            set(value) {
                this.$store.commit('suchString', value)
            }
        }
    }
}
</script>

<style scoped>
    input {
        position: relative;
        width: 323px;
        height: 20px;
        padding: 10px;
        border: none;
        border-bottom: 1px solid rgb(235, 234, 234);
        font-size: 14px;
        color: #00457c;
        letter-spacing: 0.025em;
        outline: none; /* Entfernt die Standard-Umrandung */
    }

    input:focus {
        background-color: rgba(0, 0, 255, 0.05);
        border: none;
        border-bottom: 1px solid rgb(197, 195, 195);
        caret-color: #00457c;
    }

    /* Placeholder Farbe für verschiedene Browser anpassen */
    input::placeholder { /* Standard */
        color: #00447c88;
        opacity: 1; /* Sicherstellen, dass die Farbe nicht transparent ist */
    }
    .eingabe-zeile::-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: #00447c88;
    }
    .eingabe-zeile::-moz-placeholder { /* Firefox 19+ */
        color: #00447c88;
    }
    .eingabe-zeile:-ms-input-placeholder { /* IE 10+ */
        color: #00447c88;
    }
    input::-ms-input-placeholder { /* Microsoft Edge */
        color: #00447c88;
    }
</style>
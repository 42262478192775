<template>
    <ul>
        <li v-for="kapitel in generateKapitel(lastKapitel)" 
            :key="kapitel" 
            :id="'kapitel' + kapitel" 
            :class="{ second: secondCheck(kapitel), active: aktivCheck(kapitel) }" 
            @click="handleKapitelClick(kapitel)"
            >
            {{ kapitel }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "SelectKapitelField",
    props: [ 'lastKapitel', 'kapitel' ],
    data() {
        return {
            inputValue: ''
        };
    },
    methods: {
        generateKapitel(end) {
            const numbers = [];
            for (let i = 1; i <= end; i++) {
                numbers.push(i);
            }
            return numbers;
        },
        
        handleKapitelClick(kapitel) {
            this.$emit('kapitel-item-click', kapitel);
        },
        
        secondCheck(kapitel) { 
            if (this.kapitel != kapitel) {
                if (kapitel % 2 == 0) {
                    return true;
                }
            }
            return false;       
        },

        aktivCheck(kapitel) { 
            if (this.kapitel == kapitel) {
                return true;
            }
            return false;       
        },
    }
}
</script>

<style scoped lang="scss">
ul {
    all: unset;
    position: absolute;
    top: 20px;
    width: calc(100% - 10px);
    max-height: 75vh;
    background-color: #CCC;
    padding-left: 10px;
    overflow: hidden;
    overflow-y: scroll;
    display: none;

    scrollbar-width: none; /* Für Firefox - Breite des unsichtbaren Scrollbalkens */
    &::-webkit-scrollbar {
        width: 1px; /* Breite des unsichtbaren Scrollbalkens */
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent; /* Farbe des Scrollbalken-Thumb */
    }
    &::-webkit-scrollbar-track {
        background-color: transparent; /* Farbe des Scrollbalken-Tracks */
    }

    li {
        all: unset;
        position: relative;
        width: calc(100% - 8px);
        padding: 5px;
        background-color: #EEE;
        transition: padding 0.2s ease;

        &:hover {
            cursor: auto;

            &:not(.active) {
                cursor: pointer;
                padding-left: 7px;
                background-color: #CCC;
            }
        }

        &.second {
            background-Color: #DDD;
        }

        &.active {
            background-color: #000;
            font-weight: bolder;
            color: #FFF;
        }
    }
    &:hover {
        display: flex !important;
        flex-direction: column;
        z-index: 1;
    }
}
</style>
<template>
    <div class="modal" @click="closeModal">

    <div class="closeBtn" @click="closeModal">
        <span class="line line1"></span>
        <span class="line line2"></span>
    </div>

        <div class="modal-content" @click.stop>

            <label v-if="modalTitel != ''" class="titel"> {{ modalTitel }} </label>

        </div>
    </div> 
</template>
  
<script>
//import { nextTick } from 'vue';
export default {
    name: 'Modal',
    components: {
    },
    props: {
        isShowModal: {
            type: Boolean,
            required: true
        },
        modalTitel: {
            type: String,
            required: true
        }
    },
    data() {
        return {
        };
    },

    methods: {
        closeModal() {
            this.$emit('update:isShowModal', false);
        },
    }
}
</script>

<style scoped lang="scss">
    .halbeSettingsZeile {
        width: 150px;

        &.left {
            float: left;
        }

        &.right {
            float: right;
        }
    }
    .closeBtn {
        position: absolute;
        top: 3px;
        right: 13px;
        background: none;
        border: none;
        width: 10px;
        height: 10px;
        cursor: pointer;
        padding: 0;
        z-index: 1;
    }

    .line {
        position: absolute;
        display: block;
        margin-top: 9px;
        margin-right: 8px;
        width: 20px;
        height: 1px;
        background-color: #333;
        transition: background-color 0.3s ease;
    }

    .line1 {
        transform: rotate(45deg);
    }

    .line2 {
        transform: rotate(-45deg);
    }

    .closeBtn:hover .line {
        background-color: #ff0000;
    }

    .titel {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .subTitel {
        display: block;
        position: relative;
        width: 100%;
        font-weight: bold;
        padding-bottom: 1px;
        margin-bottom: 6px;
    }

    .modal {
        position: fixed;
        display: none;
        z-index: 3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0, 69, 124, 0.45);
        scrollbar-width: none;

        .modal-content {
            position: relative;
            background-color: white;
            color: #00457c;
            margin: 25px auto;
            margin-bottom: 0;
            padding: 1rem;
            border: 1px solid #00457c;
            max-width: calc(375px - 2rem);
            height: auto;
            max-height: calc(100vh - (50px + 2rem));
            overflow: auto;
        }
    }
</style>
<template>
    
    <div class="testamen-btn-cntnr">
        
        <button
            :class="{ aktiv: $store.state.atSuchErgebnisAnsicht }"
            title="$store.state.atBtnTtl"
            @click="$store.commit('atSuchErgebnisAnsicht')">
            AT
        </button>

        <button
            :class="{ aktiv: $store.state.ntSuchErgebnisAnsicht }"
            title="$store.state.ntBtnTtl"
            @click="$store.commit('ntSuchErgebnisAnsicht')">
            NT
        </button>

    </div>

</template>

<script>
export default {
    name: "TestamentBtn",
    data() {
        return {
        }
    },
    methods: {
    },
    computed: {
    }
}
</script>

<style scoped lang="scss">
    .testamen-btn-cntnr {
        position: relative;
        width: 20px;
        height: 36px;
        padding: 2px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    button {
        all: unset;
        cursor: pointer;
        width: 15px;
        height: 15px;
        color: #999;
        cursor: pointer;
        font-size: 13px;
        
        text-align: center;
        padding-left: 2px;

        &.aktiv {
            color: #047c00;
            font-weight: bold;
        }
    }
</style>
<template>
    <button class="previousBtn triangle">
    </button>
</template>

<script>
export default {
    name: "triangleButtonLeft"
}
</script>

<style scoped lang="scss">
button {
    all: unset;
    cursor: pointer;

    &.previousBtn {
        width: 10px;
        transform: rotate(-0.25turn) !important;

        &.triangle {
            display: block;
            width: 0;
            height: 0;
            border-left: 7px solid transparent; /* Pfeilspitze nach links */
            border-right: 7px solid transparent; /* Pfeilspitze nach rechts */
            border-bottom: 7px solid #666; /* Füllfarbe des Dreiecks */
        }
    }
}
</style>
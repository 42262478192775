<template>
    <div class="such-zeilen-cntnr">
        
        <EingabeZeile 
            class="eingabezeile"
            :placeholder="'*Suchbegriff eingeben'"
            :title="'Das zu suchende hier eintragen und Enter drücken.'"
        />
        
        <TestamenBtn />
    
    </div>
</template>

<script>
import EingabeZeile from './EingabeZeile.vue';
import TestamenBtn from '../butons/TestamenBtn.vue';

export default {
    name: "SuchZeile",
    components: {
        EingabeZeile,
        TestamenBtn,
    }
}
</script>

<style scoped>
    .such-zeilen-cntnr {
        position: relative;
        width: calc(100% - 10px);
        height: auto;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: row;
        font-size: 13px;
    }
    .eingabezeile {
        width: calc(100% - 20px); 
        font-size: 13px;
    } 
</style>
<template>
    <div 
        :class="{ 'menue-spalte': true, 'unsichtbar': !$store.state.seitenLeisteStatus }"
        >

        <div class="margin-top"></div>
        
        <label class="subTitel">{{ $store.state.seitenLeisteTtl }}</label>

        <!-- SETTINGS START -->
            <Settings />
        <!-- SETTINGS ENDE -->


        <!-- HIER NUN DIE SUCHE START -->
        <SuchZeile v-if="$store.state.searchMenueStatus" 
            @keydown.enter="$store.dispatch('sucheStarten')"
        />
        <!-- HIER NUN DIE SUCHE ENDE -->


        <SuchResult v-if="$store.state.searchMenueStatus" />


        <div class="schlachterinfo">
            Bibeltext der Schlachter<br>
            Copyright © 2000 Genfer Bibelgesellschaft<br>
            Wiedergegeben mit freundlicher Genehmigung. Alle Rechte vorbehalten.<br>
        </div>

        <a href="https://www.bibelgesellschaft.com/de" class="bibelgesellschaft-link">
            https://www.bibelgesellschaft.com/de
        </a> 
        
    </div> 
</template>
  
<script>
import Loader from './Loader.vue';
import Settings from './elemente/Settings.vue';
import SuchZeile from './forms/SuchZeile.vue';
import SuchResult from './forms/SuchResult.vue';

export default {
    name: 'MenueSpalte',

    components: {
        Loader,
        Settings,
        SuchZeile,
        SuchResult,
    },

    data() {
        return {
        }
    },
    props: {
    },

    watch: {
    },

    methods: {
    },

    computed: {
        checkHinweise: {
            get() {
                return this.$store.state.hinweiseStatus
            },
            set(value) {
                this.$store.commit('changeHinweiseAnsicht', value)
            }
        },

        checkVerweise: {
            get() {
                return this.$store.state.verweiseStatus
            },
            set(value) {
                this.$store.commit('changeVerweiseAnsicht', value)
            }
        }
    }
}
</script>

<style scoped lang="scss">

    .menue-spalte {
        position: absolute;
        top: 0;
        left: 0;
        width: 280px;
        height: calc(100% - 25px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        z-index: 2;
        background-color: #F6F6F6;
        
        /* Übergangseffekte für opacity und width */
        //transition: opacity 0.3s, width 0.3s; 
        
        &.unsichtbar {
            opacity: 0;
            width: 0;
        }

        .margin-top {
            position: relative;
            display: block;
            width: calc(100% - 14px);
            height: 50px;
            padding: 7px;
            background-color: transparent;

            @media only screen and (max-width: 660px) {
                height: 25px;
            }
        }

        .subTitel {
            position: relative;
            display: block;
            width: calc(100% - 14px);
            font-size: 12px;
            font-weight: bold;
            margin-top: 25px;
            padding: 7px ;
        }

        @media only screen and (max-width: 660px) {
            height: calc(100% - 25px);
        }
    }

    .schlachterinfo {
        position: absolute;
        bottom: 22px;
        width: 100%;
        font-size: 13px;
        text-align: center;
        font-style: italic;
        font-weight: bold;

        @media only screen and (max-width: 950px) {
            //max-width: 280px;
        }
    }

    .bibelgesellschaft-link {
        position: absolute; 
        width: 100%; 
        text-align: center; 
        bottom: 0;

        @media only screen and (max-width: 950px) {
            //max-width: 280px;
        }
    }
</style>
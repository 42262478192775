<template>

    <div class="verse-navi">
        <button type="button" :class="{ scrollup: true, versteckt: this.istVersteckt }" title="zum Anfang scrollen" @click="scrollUpNow" ref="scrollUpBtn">
            {{ scrollUpIcon }}
        </button>

        <div :class="{ 'buch-kapitel-container': true, 'gross': $store.state.seitenLeisteStatus }">
            <div class="buch-menue-container">
                <triangleButtonLeft 
                    :class="{ unsichtbar: this.lastBookNrCheck() }"
                    :title="buchLeftButonTitle" 
                    @click="vorherigesBuch"
                />

                <div class="selecter buch">

                    <input 
                        class="txtField" 
                        type="text" 
                        title="Buchname Eingabefeld" 
                        placeholder="Bibelbuch" 
                        :value="this.getAktuellesBuch()" 
                        @click="setzDasBuchMittig"
                    >
                    
                    <SelectBuchField 
                        :buchNamen="buchNamen" 
                        :buchid="buchid"
                        @item-click="changeBookByField"
                    />
                    
                </div>

                <triangleButtonRight 
                    :class="{unsichtbar: this.nextBookNrCheck() }" 
                    :title="buchRightButonTitle" @click="naechstesBuch" 
                />
            </div>


            <div class="kapitel-menue-container">
                <triangleButtonLeft :class="{unsichtbar: this.lastKapitelNrCheck() }" 
                    :title="kapitelLeftButonTitle" 
                    @click="vorherigesKapitel"
                />

                <div class="selecter kapitel">
                    <input 
                        class="txtField" 
                        type="number" 
                        title="Buchname Eingabefeld" 
                        placeholder="Bibelbuch" 
                        :value="this.kapitel"
                        @click="setzDasKapitelMittig"
                        @input="updateKapitelManuell" 
                        @keydown.enter="handleEnter"
                    >

                    <SelectKapitelField 
                        :lastKapitel="lastKapitel" 
                        :kapitel="kapitel"
                        @kapitel-item-click="changeKapitelByField"
                    />

                </div>

                <triangleButtonRight 
                    :class="{unsichtbar: this.nextKapitelNrCheck() }" 
                    :title="kapitelRightButonTitle" 
                    @click="naechstesKapitel"
                />
            </div>
        </div>

        <button 
            type="button"
            :class="{ 'suchBtn': true, 'unsichtbar': $store.state.seitenLeisteStatus }"
            title="Volltextsuche" 
            @click="$store.commit('inBibelSucheAnzeigen')"
            >
            <SearchBtn />
        </button> 
    </div>

    <!-- hier werden die Verse geladen -->
    <div v-if="verseLoaded" 
        id="buch-kapitel-container"
        :class="{ 'verse-container': true, 'abstand-unten': $store.state.verweiseStatus || $store.state.hinweiseStatus }" 
        ref="meinVerseContainer" 
        @scroll="scrollUpButtonAnzeigen">
        
        <!--
        :versMenue="$store.state.seitenLeisteStatus"
        :hinweise="$store.state.hinweiseStatus"
        :verweise="$store.state.verweiseStatus"
        -->

        <Vers 
            v-for="vers in schlachterVerse" 
            :key="vers.nr"
            :vers="vers" 
            :hinweisDaten="schlachterHinweise" 
            :verweisDaten="schlachterVerweise"
            :hinweiseLoaded="hinweiseLoaded"
            :verweiseLoaded="verweiseLoaded"
            :buchid="buchid"
            :kapitel="kapitel"
            @verweis-item-click="verweisOeffnen"
        />
    </div>

    <!-- eine LadeAnimation -->
    <Loader ref="loader" />

    <Modal
        v-model:isShowModal="isShowModal" 
        :modalTitel = modalTitel
        :sucheAktiv="sucheAktiv"
        @updateSucheAktiv="sucheAktiv = $event"
        :class="{ showModal: this.isShowModal }"
    />
</template>

<script>
//import { nextTick } from 'vue';
import Vers from './Vers.vue';
import triangleButtonLeft from '../butons/ButonLeft.vue';
import SelectBuchField from './BuchSelecter.vue';
import triangleButtonRight from '../butons/ButonRight.vue';
import SelectKapitelField from './KapitelSelecter.vue';
import SearchBtn from '../butons/SearchBtn.vue';
import Loader from '../Loader.vue';

import Modal from '../Modal.vue';

export default {
    name: 'VersenSpalte',

    components: {
        Vers,
        triangleButtonLeft,
        SelectBuchField,
        triangleButtonRight,
        SelectKapitelField, 
        Modal,
        SearchBtn,
        Loader
    },
    props: {
    },

    // die Variablen die genutzt werden.
    data() {
        return {
            istVersteckt: true,                     // der ScrollUpButton anzeigen (false) / nicht anzeigen (true)
            scrollUpIcon: '»',                      // das ITEM als Button fuer ScrollUP

            //verseSettingsAktiv: false,              // der Button fuer die Einstellungen (false) / (true)
            sucheAktiv: false,                      // der Button fuer die Suche (false) / (true)
 
            // Die Standard URL Parameter
            buchid: '1',                            // die Buch ID des aktuellen Buchs
            kapitel: '1',                           // der aktuell angezeigte Kapitel

            schlachterVerse: [],                    //"CONCAT(buch_id, '_', kapitel, '_', vers) AS id, vers, inhalt, verweise" 
            schlachterHinweise: [],                 //"vers, fnt, inhalt" 
            schlachterVerweise: [],

            verseLoaded: false,
            hinweiseLoaded: false,
            verweiseLoaded: false,

            buchNamen: [],                          //"buch_id, link"
            aktivBuchid: this.buchid,

            lastKapitel: '1',

            buchLeftButonTitle: 'vorheriges Buch',
            buchRightButonTitle: 'nächstes Buch',

            kapitelLeftButonTitle: 'vorheriges Kapitel',
            kapitelRightButonTitle: 'nächstes Kapitel',

            versid: 'vers_1',
            ignoreMoveUp: true,

            meineUrl: '',

            modalContent: 'Modal Content',
            isShowModal: false,

            modalTitel: '',

            kapitelInput: '',
        }
    },

    watch: {
    },

    methods: {
        scrollUpButtonAnzeigen() { 
            this.istVersteckt = false;

            if (this.$refs.meinVerseContainer.scrollTop === 0) {
                this.istVersteckt = true;
            }
        },

        sucheStarten() {
            this.sucheAktiv = !this.sucheAktiv;

            // dadurch wird das Modal-Fenster sichbar
            this.isShowModal = !this.isShowModal;

            // den Titel beim Modal-Fenster setzen
            if (this.sucheAktiv) {
                this.modalTitel = "Volltextsuche";
            } else {
                this.modalTitel = "";
            }
        },

        // Kapiteleingabe in dem Kapitelfeld - Manuell
        updateKapitelManuell(event) {
            let intValue = event.target.value; 

            // Validate Input
            if (!isNaN(intValue) && intValue >= 1 && intValue <= this.lastKapitel) {
                this.kapitelInput = intValue;
            } else {
                this.kapitelInput = '';
            }
            
            // Aktualisiere `kapitel` mit dem neuen Wert
            if (this.kapitelInput != '') {
                this.setzDasKapitelMittig();
            } else {
                event.target.value = this.kapitel;
            }
        },

        // Kapiteleingabe Manuell beim Druecken der Taste Enter!
        handleEnter() {
            if (this.kapitelInput != '') {
                // Kapitel laden!
                this.changeKapitelByField(this.kapitelInput);
            }
        },

        AnsichtAktualisieren() {
            // Hier wird die URL angepasst
            let urlParams = this.getUrlParams();
            this.setStandardUrlParams(urlParams);

            // Hier werden nun auch die Verse wie gewuenscht ausgegeben
            this.checkVerweiseUndHinweise();
        },

        getMyUrl() {
            this.meineUrl = '';
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.forEach((value, key) => {
                this.meineUrl += (` ${key}: ${value} `);
            });
        },

        
        moveUpToVersId() {
            let container = document.getElementById('buch-kapitel-container');
            if (container) {

                // Das Ziel-Element zum Scrollen setzen
                let targetVers = document.getElementById(this.versid);
                if (targetVers) {
                    // Scrollen
                    // Nutze Vue.nextTick, um sicherzustellen, dass alle Child-Komponenten fertig gerendert sind
                    this.$nextTick(() => {
                        let scrollAmount = targetVers.offsetTop - container.scrollTop;

                        container.scrollBy({
                            top: scrollAmount
                        });

                        if (this.versid !== 'vers_1') {
                            targetVers.style.fontWeight = 'bold';
                        }

                        this.ignoreMoveUp = true;
                    });
                }
            }
        },

        verweisOeffnen(verweisId) {
            const bibelStelleArr = verweisId.split('_');
            const verweisBuchid = bibelStelleArr[0];
            const verweisKapitel = bibelStelleArr[1];

            // den zuvor fett geschriebenen Vers, das Fett geschriebene wieder entfernen...
            let aktuellMarkierterVers = document.getElementById(this.versid);
            if (aktuellMarkierterVers.hasAttribute('style')) {
                aktuellMarkierterVers.removeAttribute('style');
            }

            this.versid = 'vers_' + bibelStelleArr[2];

            this.bookUndKapitelAendern(verweisBuchid, verweisKapitel, 'verweis');
        },

        nextKapitelNrCheck() {
            if (this.kapitel == this.lastKapitel) {
                return true;
            }
            return false;
        },

        lastKapitelNrCheck() {
            if (this.kapitel == 1) {
                return true;
            }
            return false;
        },

        nextBookNrCheck() {
            if (this.buchid == 66) {
                return true;
            }
            return false;
        },

        lastBookNrCheck() {
            if (this.buchid == 1) {
                return true;
            }
            return false;
        },

        // wenn im Buchnamenfeld gedrückt wird um ein anderes Buch zu wählen
        changeBookByField(neueBuchId) {
            this.bookUndKapitelAendern(neueBuchId, 1, '');
            this.versid = 'vers_1';
        },

        changeKapitelByField(neuesKapitel) {
            this.bookUndKapitelAendern(this.buchid, neuesKapitel, '');
            this.versid = 'vers_1';
        },

        getAktuellesBuch() { 
            if (Object.keys(this.buchNamen).length > 0) {
                return this.buchNamen[this.buchid].name;
            }
            return '';
        },

        vorherigesBuch() {            
            this.bookUndKapitelAendern((this.buchid - 1), 1, '');
            this.versid = 'vers_1';
        },

        naechstesBuch() {
            this.bookUndKapitelAendern(Number(this.buchid) + 1, 1, '');
            this.versid = 'vers_1';
        },

        vorherigesKapitel() {
            this.bookUndKapitelAendern(this.buchid, this.kapitel - 1, '');
            this.versid = 'vers_1';
        },

        naechstesKapitel() {
            this.bookUndKapitelAendern(this.buchid, Number(this.kapitel) + 1, '');
            this.versid = 'vers_1';
        },

        // Springt zu vers 1 und macht anschliessend den Button unsichtbar
        scrollUpNow() { 
            // den zuvor fett geschriebenen Vers, das Fett geschriebene wieder entfernen...
            let aktuellMarkierterVers = document.getElementById(this.versid);
            if (aktuellMarkierterVers.hasAttribute('style')) {
                aktuellMarkierterVers.removeAttribute('style');
            }

            // Das Ziel-Element zum Scrollen setzen
            this.versid = 'vers_1';
            let targetVers = document.getElementById(this.versid);

            // Nur innerhalb des 'verse-container' divs scrollen
            let container = document.getElementById('buch-kapitel-container');
            if (container) {
                // Innerhalb des Containers scrollen, um das Ziel-Element nach oben zu bringen
                container.scrollTo({
                    top: targetVers.offsetTop - container.offsetTop, // Höhe relativ zum Container
                    behavior: 'smooth' // Sanfter Scroll-Effekt
                });
            }
        },

        // die hautpmethode zum Anpassen der Verseausgabe
        bookUndKapitelAendern(buchId, kapitel, art) {
            let urlUpdate = false;
            let buchIdChanged = false;

            let urlParams = this.getUrlParams();
            
            if (this.buchid != buchId) {
                this.buchid = buchId;
                urlParams.set('buchid', buchId);
                sessionStorage.setItem('buchid', buchId);

                urlUpdate = true;
                buchIdChanged = true;
            }

            if (this.kapitel != kapitel) {
                this.kapitel = kapitel;
                urlParams.set('kapitel', kapitel);
                sessionStorage.setItem('kapitel', kapitel);

                urlUpdate = true;
            }

            if (urlUpdate) {
                this.setLoadedToFalse();

                // Die aktualisierte URL mit den geänderten Parametern erstellen
                const updatedUrl = window.location.origin + window.location.pathname + '?' + urlParams.toString();
                history.replaceState(null, null, updatedUrl); // Die URL im Browser aktualisieren, ohne die Seite weiterzuleiten

                this.checkVerweiseUndHinweise();
            
                this.getBookData(); 
                this.getHinweiseData(); 
                this.getVerweiseData(); 

                if (buchIdChanged) {
                    this.getLastKapitel();
                }
                
                this.buchPfeileTitelSetzen();
                this.kapitelPfeileTitelSetzen();
            }

            // den Button "nach oben scrollen" unsichtbar machen
            this.istVersteckt = true;

            this.ignoreMoveUp = false;
        },

        setLoadedToFalse() {
            this.verseLoaded = false;
            this.hinweiseLoaded = this.$store.state.hinweiseStatus ? false : true;
            this.verweiseLoaded = this.$store.state.verweiseStatus ? false : true;
        },

        kapitelPfeileTitelSetzen() {
            if (this.kapitel > 1) {
                this.kapitelLeftButonTitle = 'Kapitel ' + (Number(this.kapitel) - 1);
            }

            if (this.kapitel < this.lastKapitel) {
                this.kapitelRightButonTitle = 'Kapitel ' + (Number(this.kapitel) + 1);
            }

            this.setzDasKapitelMittig();
        },

        buchPfeileTitelSetzen() {
            if (this.buchid > 1) {
                this.buchLeftButonTitle = this.buchNamen[(this.buchid - 1)].name;
            }

            if (this.buchid < 66) {
                this.buchRightButonTitle = this.buchNamen[parseInt(this.buchid, 10) + 1].name;
            }

            this.setzDasBuchMittig();
        },

        setzDasBuchMittig() {
            // mittig setzen im Menue
            let elementId = 'buchId' + this.buchid; 
            this.getIdElementIfReady(elementId, function(myBookElement) {
                myBookElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
        },

        setzDasKapitelMittig() {
            // mittig setzen im Menue
            let elementId = 'kapitel' + this.kapitel;
            this.getIdElementIfReady(elementId, function(myKapitelElement) {
                myKapitelElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
        },

        // holt die URL Parameter
        getUrlParams() {
            const queryString = window.location.search; // Aktuelle URL holen
            const urlParams = new URLSearchParams(queryString); // URLSearchParams-Objekt erstellen
            return urlParams;
        },

        // Das hier soll aufgerufen werden, wenn die Seite zuerst aufgerufen wird und noch kein Buch oder Kapitel ausgegeben wurde
        setStandardUrlParams(urlParams) {
            urlParams.set('buchid', this.buchid);
            sessionStorage.setItem('buchid', this.buchid);

            urlParams.set('kapitel', this.kapitel);
            sessionStorage.setItem('kapitel', this.kapitel);

            // Die aktualisierte URL mit den geänderten Parametern erstellen
            const updatedUrl = window.location.origin + window.location.pathname + '?' + urlParams.toString();
            history.replaceState(null, null, updatedUrl); // Die URL im Browser aktualisieren, ohne die Seite weiterzuleiten
        },
        
        
        async getBookNames() {
            // Daten in die Komponenten-Daten laden
            if (Object.keys(this.$store.state.buchNamenArray).length === 0) {
                await this.$store.dispatch('getBookNames');
                this.buchNamen = this.$store.state.buchNamenArray;
            } else {
                this.buchNamen = this.$store.state.buchNamenArray;
            }
        },

        getLastKapitel() {
            if (this.$store.state.myRoot === '') {
                this.$store.commit('getMyRoot');
            }

            fetch(this.$store.state.myRoot + '/api/ajax_kapitel.php?buch-id=' + this.buchid)
                .then(response => {
                    // JSON-Daten aus der Antwort extrahieren
                    return response.json();
                })
                .then(kapitelData => {
                    // Daten in die Komponenten-Daten laden
                    this.lastKapitel = kapitelData; 
                })
                .catch(error => {
                    console.error('Fehler bei der Datenbankabfrage:', error);
                });
        },
        
        checkVerweiseUndHinweise() {
            if (!this.$store.state.hinweiseStatus) {
                this.hinweiseLoaded = true;
            }

            if (!this.$store.state.verweiseStatus) {
                this.verweiseLoaded = true;
            }
        },

        replaceBackslashes(obj) {
            if (typeof obj === 'string') {
                return obj.replace(/\\/g, '');
            } else if (Array.isArray(obj)) {
                return obj.map(item => this.replaceBackslashes(item));
            } else if (typeof obj === 'object' && obj !== null) {
                return Object.fromEntries(
                    Object.entries(obj).map(([key, value]) => [key, this.replaceBackslashes(value)])
                );
            }
            return obj;
        },

        // Holt die Buchinfos aus der DB
        async getBookData() { 
            if (this.$store.state.myRoot === '') {
                this.$store.commit('getMyRoot');
            }

            //this.$refs.loader.startLoading();
            this.$store.commit('starteLadeschleife', 'Lade Buchinformation ...');
            this.schlachterVerse = [];
            try {
                // Den Ladebalken anzeigen
                let response = await fetch(this.$store.state.myRoot + '/api/ajax_verse.php?buchid=' + this.buchid + '&kapitel=' + this.kapitel);
                let bookData = await response.json();

                // Daten in die Komponenten-Daten laden
                this.schlachterVerse = bookData;
                this.$nextTick(() => {
                    if (Object.keys(this.schlachterVerse).length > 0) {
                        this.verseLoaded = true;

                        // wenn keine Hinweise vorhanden,
                        if (!this.schlachterVerse.hinweise) {
                            this.hinweiseLoaded = true;
                        }

                        // wenn keine Verweise vorhanden,
                        if (!this.schlachterVerse.verweise) {
                            this.verweiseLoaded = true;
                        }

                        // Entferne die Schlüssel 'hinweise' und 'verweise'
                        delete this.schlachterVerse.hinweise;
                        delete this.schlachterVerse.verweise;
                    } else {
                        this.verseLoaded = false;
                    }
                });
            } catch (error) {
                console.error('Fehler bei der verse - Datenbankabfrage:', error);
            }
        },

        async getHinweiseData() {
            if (this.$store.state.myRoot === '') {
                this.$store.commit('getMyRoot');
            }

            this.schlachterHinweise = [];
            try {
                let fancyUrl = this.$store.state.myRoot + '/api/ajax_hinweise.php?buchid=' + this.buchid + '&kapitel=' + this.kapitel; 
                let response = await fetch(fancyUrl);
                let hinweiseData = await response.json();

                // Zeichen '\' in den Werten ersetzen - wichtig zB bei 1 Petrus, Kap 1, Vers 5
                this.schlachterHinweise = this.replaceBackslashes(hinweiseData);

                // Daten in die Komponenten-Daten laden
                this.$nextTick(() => {
                    if (Object.keys(this.schlachterHinweise).length > 0) {
                        this.hinweiseLoaded = true;
                    } else {
                        this.hinweiseLoaded = false;
                    }
                });
            } catch (error) {
                console.error('Fehler bei der hinweise - Datenbankabfrage:', error);
            }
        },

        async getVerweiseData() {
            if (this.$store.state.myRoot === '') {
                this.$store.commit('getMyRoot');
            }

            this.schlachterVerweise = [];
            try {
                let response = await fetch(this.$store.state.myRoot + '/api/ajax_verweise.php?buchid=' + this.buchid + '&kapitel=' + this.kapitel);
                let verweiseData = await response.json();
           
                this.schlachterVerweise = verweiseData;
                this.$nextTick(() => {
                    if (Object.keys(this.schlachterVerweise).length > 0) {
                        this.verweiseLoaded = true;
                    } else {
                        this.verweiseLoaded = false;
                    }
                });
            } catch (error) {
                console.error('Fehler bei der verweise - Datenbankabfrage:', error);
                this.$store.commit('beendeLadeschleife');
            }
            //this.$refs.loader.stopLoading();
            this.$store.commit('beendeLadeschleife');
        },

        async getIdElementIfReady(elementId, callback) {
            let newElement = document.getElementById(elementId);
            let counter = 0;

            while (newElement === null && counter < 50) {
                await new Promise(resolve => setTimeout(resolve, 10));
                newElement = document.getElementById(elementId);
                counter++;
            }
            callback(newElement);
        },

        currentTime() {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

            return `${hours}:${minutes}:${seconds}:${milliseconds}`;
        }
    },

    mounted() {
        // Check ob buchid vorhanden ist
        let urlParams = this.getUrlParams();
        if (!urlParams.has('buchid')) {
            this.setStandardUrlParams(urlParams);
        } else {
            this.buchid = urlParams.get('buchid');
            sessionStorage.setItem('buchid', this.buchid);

            this.kapitel = urlParams.get('kapitel');
            sessionStorage.setItem('kapitel', this.kapitel);
        }

        this.checkVerweiseUndHinweise();
        this.getBookNames();
        this.getBookData();
        this.getHinweiseData();
        this.getVerweiseData();
        this.getLastKapitel();
    }, 

    updated() {
        /**
         * das hier soll dafür sorgen, dass es nur dann zum Vers springt, wenn 
         * 1. Die Änderung durch das Verschwindens des GetUp Buttons eintritt...
         * 2. Der Optionen Button angeklickt wird
        **/ 

        if (!this.ignoreMoveUp) {
            this.moveUpToVersId();
            //console.log('Das DOM im VersenSpalte wurde geändert.');

            this.getMyUrl();
        } 
    },

    unmounted() {
    }
}
</script>

<style scoped lang="scss">
    .suchBtn {
        position: relative;
        cursor: pointer;
        margin-right: 10px;

        &.unsichtbar {
            display: none;
        }
    }
    .showModal {
        display: block !important;
    }
    .unsichtbar {
        visibility: hidden !important;
    }
    .verse-navi {
        position: relative;
        width: 100%; 
        height: 20px; 
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        display: flex; 
        justify-content: space-between;
        align-items: center;

        button {
            all: unset;
            cursor: pointer;

            &.scrollup {
                position: relative;
                width: 20px;
                height: 20px;
                transform: rotate(-0.25turn);
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                color: #AAA;

                &.versteckt {
                    visibility: hidden;
                }
            }

            &.settingsBtn {
                position: relative;
                display: block;
                margin-right: 5px;
                width: 20px;
                height: 20px;
                background-image: url(../../assets/settings_color.svg);
                opacity: 1;
                background-size: 20px 20px;
                float: right;
            }
        }

        .buch-kapitel-container {
            position: relative;
            width: calc(100% - 90px);
            height: 20px;

            display: flex; 
            align-items: center;
            justify-content: space-around;

            &.gross {
                width: calc(100% - 45px);
            }

            /* Das ist der Reine Container, der die Buchnamen und Navi innehat */
            .buch-menue-container {
                position: relative;
                width: 175px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                //background-color: rgba(0, 0, 255, 0.05);
                .selecter {
                    position: relative;
                    height: auto;
                    width: auto; 

                    display: flex; 
                    align-items: center;
                    flex-direction: column;

                    &.buch {
                        /* die 20px sollten fuer die Buttons next und last reserviert sein */
                        width: calc(100% - 20px);

                        input {
                            all: unset;
                            position: relative;
                            width: calc(100% - 12px);
                            border: 1px inset transparent;
                            font-weight: bold;
                            line-height: 100%;

                            &.txtField {
                                //background-color: #F9F9F9;
                                padding: 0 5px;
                                //background-color: rgba(0, 0, 255, 0.05);
                            }

                            &:hover,
                            &:focus {
                                border: 1px inset;
                                cursor: pointer;

                                ~ ul {
                                    display: flex;
                                    flex-direction: column;
                                    z-index: 1;
                                }
                            }

                            &:not(:focus) {
                                ~ ul {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .kapitel-menue-container {
                position: relative;
                width: 80px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                //background-color: rgba(0, 0, 255, 0.05);

                /* Die Select-Box - Buchnamen */
                .selecter {
                    position: relative;
                    height: auto;
                    width: auto; 

                    display: flex; 
                    align-items: center;
                    flex-direction: column;

                    &.kapitel {
                        // die 20px sollten fuer die Buttons next und last reserviert sein
                        width: calc(100% - 20px);

                        input {
                            all: unset;
                            position: relative;
                            width: calc(100% - 12px);
                            border: 1px inset transparent;
                            font-weight: bold;
                            line-height: 100%;
                            text-align: center;

                            &.txt-field {
                                background-color: #F9F9F9;
                                padding: 0 5px;
                            }

                            &:hover,
                            &:focus {
                                border: 1px inset;
                                cursor: pointer;

                                ~ ul {
                                    display: flex;
                                    flex-direction: column;
                                    z-index: 1;
                                }
                            }

                            &:not(:focus) {
                                ~ ul {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .verse-container {
        position: relative;
        width: 100%;
        max-height: calc(100% - 21px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow-y: scroll;
        background-color: #FFF;

        scrollbar-width: none; /* Für Firefox - Breite des unsichtbaren Scrollbalkens */
        &::-webkit-scrollbar {
            width: 1px; /* Breite des unsichtbaren Scrollbalkens */
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent; /* Farbe des Scrollbalken-Thumb */
        }
        &::-webkit-scrollbar-track {
            background-color: transparent; /* Farbe des Scrollbalken-Tracks */
        }
    }

    .abstand-unten { 
        padding-bottom: 200px;
    }
</style>
<template>

    <div class="navi">

        <div :class="{ 'seiten-leiste': true }">
    
            <button 
                type="button" 
                :class="{ 'menue-btn': true, 'center': $store.state.seitenLeisteStatus, 'anklickbar': true }"
                @click="$store.commit('seitenLeisteToggle')"
                :title="$store.state.seitenLeisteButtonTitle">
                <div :class="{ 'icon': $store.state.seitenLeisteStatus, 'unsichtbar': !$store.state.seitenLeisteStatus }"></div>
                <div :class="{ 'icon-point': !$store.state.seitenLeisteStatus, 'unsichtbar': $store.state.seitenLeisteStatus }"></div>
                <div :class="{ 'icon-half': !$store.state.seitenLeisteStatus, 'unsichtbar': $store.state.seitenLeisteStatus }"></div>
            </button>

            <button 
                type="button"
                :class="{ 'menue-btn': true, 'settingsBtn': true, 'unsichtbar': !$store.state.seitenLeisteStatus, 'anklickbar': !$store.state.settingsMenueStatus }"
                title="Einstellungen" 
                @click="$store.state.settingsMenueStatus ? null : $store.commit('einstellungenAnzeigen')">
                <div :class="{ 'icon': $store.state.seitenLeisteStatus, 'aktiv': $store.state.settingsMenueStatus }"></div>
                <div :class="{ 'icon': $store.state.seitenLeisteStatus, 'aktiv': $store.state.settingsMenueStatus }"></div>
            </button> 

            <button 
                type="button"
                :class="{ 'menue-btn': true, 'unsichtbar': !$store.state.seitenLeisteStatus, 'anklickbar': !$store.state.searchMenueStatus }"
                title="Volltextsuche" 
                @click="$store.state.searchMenueStatus ? null: $store.commit('inBibelSucheAnzeigen')">
                <div :class="{ 'lupe-kreis': true, 'aktiv': $store.state.searchMenueStatus }"></div>
                <div :class="{ 'lupe-griff': true, 'aktiv': $store.state.searchMenueStatus }"></div>
            </button> 

        </div>
    
    </div>  

</template>

<script>


export default { 
    name: "navi", 
}
</script>

<style scoped lang="scss">
    .navi {
        position: sticky; //static;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-width: 280px;
        height: 50px;
        float: left;
        z-index: 3;
        //background-color: #F6F6F6;
        background-color: transparent;

        @media only screen and (max-width: 660px) {
            height: 25px;
        }

        .menue-btn {
            all: unset;

            position: relative;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 22px;
            min-height: 22px;
            border: 3px solid #999;
            border-radius: 5px;
            z-index: 3;

            &.anklickbar {
                &:hover {
                    cursor: pointer;
                    //border-color: #00457c;
                    border-color: #888;
                    //background-color: #F6F6F6;
                }
            }

            &.center {
                justify-content: center;
            }

            .icon {
                position: relative;
                width: 16px;
                height: 3px;
                background-color: #999;
                border: none;

                &.aktiv {
                    background-color: #00457c;
                }
            }

            .unsichtbar {
                display: none;
            }

            .icon-point {
                position: relative;
                width: 3px;
                height: 3px;
                background-color: #999;
                border: none;
            }
            
            .icon-half {
                position: relative;
                width: 10px;
                height: 3px;
                background-color: #999;
                border: none;
            }

            @media only screen and (max-width: 660px) {
                border: none;
            }

            &.unsichtbar {
                display: none;
            }
        }

        .seiten-leiste {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            width: calc(280px - 22px);
            padding: 0 11px;

            @media only screen and (max-width: 660px) {
                height: 25px;
            }
        }

        .lupe-kreis {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 8px;
            height: 8px;
            border: 3px solid #999;
            border-radius: 50%;
            display: inline-block;

            &.aktiv {
                border-color: #00457c;
            }
        }

        .lupe-griff {
            position: absolute;
            bottom: 6px;
            right: 0;
            width: 8px;
            height: 3px;
            background-color: #999;
            transform: rotate(50deg);
            transform-origin: top left;

            &.aktiv {
                background-color: #00457c;
            }
        }

        .settingsBtn {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }
    }
</style>
<template>

    <div class="main">
        
        <MenueSpalte />
        
        <Spalte />

    </div> 
    
</template>

<script>
import Spalte from './Spalte.vue';
import MenueSpalte from './MenueSpalte.vue';

export default {
    name: 'Main',
    components: {
        Spalte,
        MenueSpalte
    },
    
    data() {
        return {
        }
    },
}
</script>

<style scoped lang="scss">
    .main {
        position: static;
        width: 100%;
        min-width: 280px;
        height: calc(100vh - 75px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;
        overflow: hidden;

        
        @media only screen and (max-width: 660px) {
            height: calc(100vh - 50px);
        }
    }
</style>
  